import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import styled from "@emotion/styled";

// Add ground vehicles
// Add column where ship is sold in game

export default function ShipCostUecDollarRatio(props) {
  const data = props.pageContext.shipData;
  const columns = useMemo(
    () => [
      {
        Header: "Ship",
        accessor: "shipName",
      },
      {
        Header: "Mfr",
        accessor: "manufacturer",
      },
      {
        Header: "aUEC",
        accessor: "auecPrice",
        sortInverted: true,
        Cell: (props) => props.value != null ? props.value.toLocaleString() : "N/A",
      },
      {
        Header: "USD",
        accessor: "storePrice",
        sortInverted: true,
        Cell: (props) => props.value != null ?
          props.value
            .toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) : "N/A",
      },
      {
        Header: "aUEC/Dollar",
        accessor: "auecDollar",
        Cell: (props) => props.value != null ? props.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) : "N/A",
      },
      { Header: "Shop", accessor: "shopNames" },
      { Header: "Pledge Availability", accessor: "availability" }
      // { Header: "PTU Price", accessor: "ptuPriceDifference", Cell: (props) => (props.value != null ? props.value.toLocaleString() : "") },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const title = "Ship Cost aUEC/Dollar Cost Ratio";
  const metaDescription = "Compare prices of ships in aUEC and dollars to see the values of ships in game vs in the pledge store.";
  const metaUrl = "https://scdata.io/ship-cost-uec-dollar-ratio/";
  const metaImage = "";
  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ScData.io`}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="2445424502355895" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="675" />
        <meta property="og:image:alt" content={title} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="https://scdata.io/" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <html lang="en" />
      </Helmet>
      <Container>
        <h1>Ship Cost aUEC/Dollar Comparison</h1>
        <p>Compare the cost of ships in game for aUEC vs on the website for dollars.</p>
        <p>This page shows flyable ships only.</p>
        <p>Some ships are much more expensive on the website than they are in the game, you might want to prioritize buying these ships with aUEC.</p>
        <br />
        <TableInfo>Hold Shift + Click on column to sort by multiple columns.</TableInfo>
        <FlexContainer>
          <Table {...getTableProps()}>
            <Thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                        </Th>
                      ))
                    }
                  </Tr>
                ))
              }
            </Thead>
            {/* Apply the table body props */}
            <Tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <Tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <Td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </Td>
                          );
                        })
                      }
                    </Tr>
                  );
                })
              }
            </Tbody>
          </Table>
        </FlexContainer>
      </Container>
    </Layout>
  );
}
const Container = styled.div`
`;
const FlexContainer = styled.div`
  z-index: 5;
  display: flex;
  /* flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 45em) {
    align-content: start;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    justify-content: initial;
  } */
`;
const TableInfo = styled.div`
  color: var(--alternate-color);
`;
const Table = styled.table`
  display: block;
  overflow-x: auto;
  border-collapse: collapse;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  @media (min-width: 490px) {
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
  
  }
`;
const Thead = styled.thead`
  background-color: var(--accent-color);
  color: #ffffff;
  text-align: left;
`;
const Tbody = styled.tbody`
`
const Tr = styled.tr`
  border-bottom: 1px solid var(--tr-border-color);
  &:nth-of-type(even) {
    background-color: var(--tr-background-color);
  }
  &:last-of-type {
    border-bottom: 2px solid var(--accent-color);
  }
`;
const Td = styled.td`
  padding: 2px;
  @media (min-width: 490px) {
    padding: 12px 15px;
  }
`;
const Th = styled.th`
  
  @media (min-width: 490px) {
    padding: 12px 15px;
  }
`;
